import { fetchTimeout } from "./fetch-timeout";
import { getCookieByName, setCookie } from "./cookie";
import { callbackSyncShoppingCart } from "./appCallback";

export async function handleAddToCartAndSyncApp(event, artikelnummern) {
    event.preventDefault();
    const buttonCustomElement = getCustomElementButton(event.target);
    setButtonState(buttonCustomElement, "loading");

    const body = buildRequestBody(artikelnummern);
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    };
    let wkCookieId = getCookieByName("warenkorb_id");
    const response = await fetchTimeout(`/api/warenkorb/v2/${wkCookieId}`, config);
    if (response.status === 200) {
        const jsonResponse = await response.json();
        const warenkorbId = jsonResponse.warenkorbId;

        if ( wkCookieId === "" || wkCookieId != warenkorbId ) {
            setCookie("warenkorb_id", warenkorbId)
        }

        callbackSyncShoppingCart(event.target, warenkorbId);
        setButtonState(buttonCustomElement, "success");
    }
}

export function buildRequestBody(artikelnummern) {
    let data = {
        "positionen": []
    }
    artikelnummern.forEach(artikelnummer => {
        data.positionen.push({
            "artikelnummer": artikelnummer,
            "menge": 1
        });
    });
    return data;
}

function setButtonState(wkButton, state) {
    if (wkButton !== null) {
       wkButton.setAttribute("data-state", state);
    }
}

function getCustomElementButton(context) {
    return context.closest("warenkorb-button-vis") || context.closest("warenkorb-button-artikelfokus") || context.closest("warenkorb-button-listenkauf") || context.closest("warenkorb-button-merkzettel") || context.closest("addtocart-button");
}