
export function useOverlay(context, artikelnummer) {
    const artikelnummernString = sanitizeArtikelnummerString(artikelnummer);
    context.addEventListener("click", (event) => handleClickEvent(event, artikelnummernString));
}

function handleClickEvent(event, artikelnummer) {
    const target = event.target;
    if (target.closest(".warenkorb-button")) {
        event.preventDefault();
        document.dispatchEvent(new CustomEvent("open-wk-overlay", { detail: {artikelnummer: artikelnummer, origin: target}, bubbles: true }))
    }
}

export function sanitizeArtikelnummerString(artikelnummern) {
    return artikelnummern.replace(/[^A0-9,]/g, '')
}