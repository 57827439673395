import {handleAddToCartAndSyncApp} from "../../common/appShoppingCart";
import {useOverlay} from "../../common/step0Overlay";

class AddtocartButton extends HTMLElement {
  static get observedAttributes() {
    return ["data-state"];
  }

  connectedCallback() {
    if(this.dataset.statex === "true"){
      return;
    }
    this.declareElements();
    this.renderButtons();
  }


  attributeChangedCallback(attributename, oldValue, newValue) {
    if (oldValue === newValue) {
      return;
    }
    if (attributename === "data-state") {
      this.changeButtonState(newValue);
    }
  }

  declareElements() {
    this.artikelnummer = this.dataset.artikelnummer;
    this.scriptTag = document.querySelector("script[name=addtocart]");
    this.isApp = (this.scriptTag.getAttribute("is-app") === "true");
  }

  renderButtons() {
    this.linkElement = this.createLinkElement();
    this.appendChild(this.linkElement);
    if (this.isApp === true) {
      this.setClickListener();
    }
    if (this.isApp != true) {
      useOverlay(this, this.artikelnummer);
    }
  }

  createLinkElement() {
    const link = document.createElement("a");
    link.href = `/warenkorb/hinzufuegen?artikelnummer=${this.artikelnummer}`;
    link.title = "Artikel hinzufügen";

    const button = document.createElement("BUTTON");
    button.classList.add("element-button-primary-icon");
    button.classList.add("warenkorb-button");
    button.setAttribute("icon", "cart");
    button.setAttribute("basket", "add");
    button.setAttribute("product-id", this.artikelnummer);

    const span = document.createElement("span");
    span.classList.add("element-icon-warenkorb-hinzufuegen");
    span.classList.add("icon-warenkorb-button");
    span.setAttribute("data-size","medium");
    button.appendChild(span);

    link.appendChild(button);

    return link;
  }

  setClickListener() {
    const link = this.querySelector("a");
    link.addEventListener("click", (event) => handleAddToCartAndSyncApp(event, [ this.artikelnummer ]));
  }

  changeButtonState(newState) {
    const wkButton = this.querySelector("button");
    const icon = this.querySelector(".icon-warenkorb-button");
    if (newState === "loading") {
      icon.classList.remove("element-icon-warenkorb-hinzufuegen");
      icon.classList.remove("element-icon-haekchen");
      icon.classList.add("element-icon-ladeindikator");
      wkButton.setAttribute("icon", "loading");
      this.resetButtonState(wkButton, icon,5000);
    } else if (newState === "success") {
      icon.classList.remove("element-icon-warenkorb-hinzufuegen");
      icon.classList.remove("element-icon-ladeindikator");
      icon.classList.add("element-icon-haekchen");
      wkButton.setAttribute("icon", "checkmark");
      this.resetButtonState(wkButton, icon, 2000);
    }
  }

  resetButtonState(wkButton, icon, time) {
    setTimeout(() => {
      icon.classList.remove("element-icon-ladeindikator");
      icon.classList.remove("element-icon-haekchen");
      icon.classList.add("element-icon-warenkorb-hinzufuegen");
      wkButton.setAttribute("icon", "cart");
    }, time);
  }

}

if (!customElements.get("addtocart-button")) {
  customElements.define("addtocart-button", AddtocartButton);
}
