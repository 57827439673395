export {fetchTimeout};

async function fetchTimeout(url, config, passHTTP400, timeout) {
    const additionalConfig = {
        credentials: 'same-origin',
        signal: requestTimeoutSignal(timeout)
    };
    const options = Object.assign({}, additionalConfig, config);

    return await submit(url, options, passHTTP400);
}

function requestTimeoutSignal(timeout = 5000) {
    const controller = new AbortController();
    setTimeout(() => {
        controller.abort();
    }, timeout);
    return controller.signal;
}

async function submit(url, config, passHTTP400 = false) {
    try {
        const response = await fetch(url, config);
        if (response.status.toString().indexOf('2') !== 0 && passHTTP400 === false) {
            console.log('fetch-Response:', response);
            throw new Error('Request failed');
        }
        return response;
    } catch (error) {
        if (error.name === 'AbortError') {
            throw new Error('Request timed out');
        }
        throw error;
    }
}